<template>
  <div>
    <div class="search-form">
      <div class="search-input">
        <Select style="width:200px;margin-right:10px" v-model="selectValue" placeholder="请选择操作类型">
          <Option value="all">全部</Option>
          <Option value="0">浏览</Option>
          <Option value="1">点赞</Option>
          <Option value="2">分享</Option>
        </Select>
        <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button>
        <Button type="info" @click="closeModel">关闭</Button>
      </div>
    </div>
    <LiefengTable
      :talbeColumns="talbeColumns"
      :tableData="tableData"
      :loading="loading"
      :fixTable="true"
      :curPage="page"
      :total="total"
      :pagesizeOpts="[20, 30, 50, 100]"
      :page-size="pageSize"
      @hadlePageSize="hadlePageSize"
    ></LiefengTable>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: {
    businessType: String,
    infoId: String,
    isShow: false
  },
  data() {
    return {
      // 表格的数据内容
      talbeColumns: [
        {
          title: "姓名",
          key: "userName",
          width: 80,
          align: "center"
        },
        {
          title: "性别",
          width: 120,
          key: "sex",
          align: "center"
        },
        {
          title: "身份类型",
          minWidth: 120,
          key: "idNumType",
          align: "center"
        },
        {
          title: "社区名称",
          minWidth: 120,
          key: "projectName",
          align: "center"
        },
        {
          title: "街道名称",
          minWidth: 120,
          key: "streeName",
          align: "center"
        },
        {
          title: "手机号码",
          minWidth: 120,
          key: "phone",
          align: "center"
        },
        {
          title: "操作次数",
          width: 120,
          key: "count",
          align: "center"
        },
        {
          title: "操作类型",
          width: 120,
          key: "msgType",
          align: "center"
        },
        {
          title: "操作时间",
          minWidth: 120,
          key: "createTime",
          align: "center"
        }
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 50,

      //搜索框数据信息
      selectValue: "" //搜索框绑定数据
    };
  },
  methods: {
    // 方法部分
    closeModel() {
      this.$emit("userSave", false, this.selectValue);
      this.selectValue = "";
    },
    // 点击查询按钮方法
    searchBtn() {
      if (this.selectValue == "all") {
        this.selectValue = "";
        this.hadlePageSize({
          page: 1,
          pageSize: 20
        });
      } else {
        this.hadlePageSize({
          page: 1,
          pageSize: 20
        });
      }
    },
    // 接口部分
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/old/api/pc/messgaeBrowse/selectMessageBrowsePage", {
        businessType: this.businessType,
        infoId: this.infoId,
        msgType: this.selectValue,
        page: obj.page,
        pageSize: obj.pageSize
      }).then(res => {
        this.loading = false;
        let data = res.dataList;
        console.log("获取的table表格数据", data);
        data.map(item => {
          item.userName = item.userName ? item.userName : "未知";
          item.sex = item.sex == 1 ? "男" : item.sex == 2 ? "女" : "未知";
          item.msgType =
            item.msgType == 0
              ? "浏览"
              : item.msgType == 1
              ? "点赞"
              : item.msgType == 2
              ? "分享"
              : item.msgType == 3
              ? "收藏"
              : item.msgType == 5
              ? "留言"
              : "未知";
          item.age = item.age ? item.age : "未知";
          item.idNumType =
            item.idNumType == 1
              ? "内地身份证"
              : item.idNumType == 2
              ? "港澳台身份证"
              : item.idNumType == 3
              ? "护照"
              : item.idNumType == 4
              ? "其他"
              : "无";
          item.createTime = this.$core.formatDate(
            new Date(item.createTime),
            "yyyy-MM-dd hh:mm:ss"
          );
        });
        this.tableData = data;
        this.total = res.maxCount;
      });
    }
  },
  //   created() {

  //     this.hadlePageSize({
  //       page: 1,
  //       pageSize: 20
  //     });

  //   },
  components: {
    LiefengTable
  },
  watch: {
    isShow: {
      handler(val, newVal) {
        if (val) {
          this.hadlePageSize({
            page: 1,
            pageSize: 20
          });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search-form {
  display: flex;
  position: fixed;
  top: 8px;
  right: 10px;
  z-index: 999;
  .search-input {
    margin-left: auto;
    display: flex;
    margin-bottom: 10px;
  }
}
</style>